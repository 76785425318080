:root{
  --themeclr: #001489;
  --themegrad: linear-gradient(90deg, #957bfe 22%, #c49efb 70%, #91d3f3 105%);
  --aqua: #47bfa2;
  --landtheme:#F5E600
}
@font-face {
  font-family: "maka";
  src: url(../src/Assets/lang/Mako-Bold.otf);
}




.poppins{
  font-family: 'Poppins';
}
.blur{
  filter: blur(4px) brightness(0.4);
  
}
.headfont {
  font-family: "maka";
  position: relative;
}
.aqua{
  color: #82E3F1;
}
.btn-link
{
  font-size: 13px !important;
  font-weight: 400 !important;
}
.landing .themeclr{
  color: var(--landtheme);
}
body{
  color: #fff;
}
.w-full{
  width: 100%;
}
.themeclr{
  color:#3a56f8
}
.textaqua{
  color: var(--aqua);
}
.textaqua_blue
{
  color:#5c9da7;
  font-size: 12px !important;
}
.fw-700{
  font-weight: 700;
}
.upper{
  text-transform: uppercase;
}
.nounder{
  text-decoration: none;
}
button:focus{
  box-shadow: none !important;
}
.breakword{
  word-break: break-all;
}.aqua.btn.btn-link:focus{
  box-shadow: none;
}
.dashsec .themescroll .rdt_TableCell div{
  overflow: visible !important;
}
.container-theme{
  /* width: 95%; */
}

.fa.fa-eye-slash:hover,.fa.fa-eye:hover{
  cursor: pointer
}
textarea.form-control{
  background: #191919;
  border-color: #191919;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
    padding: 5px;
    /* height: 42px; */
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}
.transscroll .btn {
  font-weight: 500;
  text-transform: capitalize !important;
  font-size: 14px !important;
}
     
.ciclechart {
  background-image:conic-gradient(#dedcdc 0 118deg, #4a4ce4 10deg 90deg);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
}
.ciclechart.violet{
  background-image: conic-gradient(#dedcdc 0 180deg, #c999fc 10deg 90deg);
}
.headfont {
  word-break: break-all;
  text-align: left;
}
.dashboard .page{
  padding-top: 55px;
}
.portscroll{
  height: 300px;
    overflow-y: auto;
}
/* .flagimg{
  max-width: 580px;
  max-height: 100% !important;
  position: absolute;
  filter: blur(1px);
  opacity: 0.1;
  top: 0;
  bottom: 0;
} */
.iconbtn.w-28{
  min-width: 28px !important;
border-radius: 3px !important;
}
.deposittabs{
  position: relative;
}
.potfolio {
  position: relative;
}
.button:focus{
  box-shadow: none;
}
.br-10.input-group input {
  border-radius: 10px;
}
.br-10.input-group .input-group-text{
  border-radius: 10px;
}
.viewmore {
  min-width: 40px !important;
  width: 40px !important;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
}
.input-group .btn-primary {
  background: #191919;
  border-color: #191919;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #2c2b2b;
  opacity: 1;
}
.viewmore span {
  font-size: 18px;
  color: #fff;
  margin-top: 3px;
}
.ciclechart p {
  position: absolute;
  height: 70px;
  width: 70px;
  background: #1a1a1a;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}
.tablescroll{
    overflow-y: auto;
    max-height: 242px;
    height: 78%;
}
.notification .d-flex img {
  width: 50px;
  height: 50px;
  border-radius: 7px;
  margin-right: 5px;
}
.profbox{
  /* width: 155px; */
  width: 120px;
    margin: auto;
    height: 122px;
    border: 1px solid #38383a;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  margin: auto;
  /* overflow: hidden; */
}
.cryptfiatlist button {
  padding: 5px 14px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}
.rightinput{
  position: relative;
}
.rightinput p {
  text-align: right;
  /* position: absolute;
    right: 110px; */
    color: #fff;
    font-size: 13px;
    margin-top: 2px;
    font-family: "poppins";
}
.profdetails p.text-muted {
  font-size: 13px;
  color:#B4B2B2 !important;
  font-weight: 400 !important;
}
.profdetails p.headfont {
  font-size: 14px !important;
  color:#FCFCFC !important;
  font-family: "poppins" !important;

}
.colorcards > div {
  /* background-size: 386%; */
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 8px;
  padding: 15px;
  background-position: center;
}
.kycdetails p , .kycdetails .btn-link{
  /* font-size: 22px; */
}
.notify hr{
  border-color: #ffffff57;
}
.trunkon200{
  max-width: 200px;
  white-space: break-spaces;
}
.notify .themescroll {
  background: #202020;
  height: 250px;
  overflow-x: auto;
}
.portscroll.themescroll {
  height: 278px;
  overflow-x: auto;
}
.notify .dropdown-item:hover {
  background-color: #000000;
}
.notify button{
  background: transparent !important;
  border-color: transparent !important;
}
.nav.nav-tabs li.nav-item button.active{
  font-family: "maka";
  font-weight: 800;

}
.notify button:after{
  display: none;
}
.notify button:focus{
  box-shadow: none !important;
}
.purplecard {
  background-image: url(../src/Assets/Images/purple_bg_new_card.png);
  background-color: #6647bf;
  background-size: 100% 100%;

}

.yellowcard{
  background-image: url(../src/Assets/Images/bg.png);
   background-color: #f1d107;
}
.purple2card{
  background-image: url(../src/Assets/Images/bg.png);
   background-color: #6647bf;
}
.aquacard{
  background-image: url(../src/Assets/Images/bg.png);
   /* background-color: #47bfa2; */
   background-color: #5b51c3 !important;
}
/* .colorcards {
  height: 267px;
  overflow: auto;
} */
.lightgreen{
  color: #5b51c3 !important
}
.marque {
  width: 100%;
  margin: auto;
  display: flex;
  padding-left: 20px;
  font-size: 12px;
  padding-right: 20px;
}
.colorcards > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
}
.coinimg {
  height: 40px;
}
.mw-120{
  max-width: 120px;
}
.br-0,.btn.br-0.nav-link, .btn.br-0.btn-theme{
  border-radius: 0 !important;
}
.br-15 {
  border-radius: 15px;
}
.h-100{
  height: 100%;
}
.br-10 {
  border-radius: 10px;
}
.br-6 {
  border-radius: 6px;
}
.fw-300{
  font-weight: 300;
}
select.form-select option {
  background: #050505;
}
.show.dropdown > div {
  background-color: #202020 !important;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #181b1d 95%);
  width: 100%;
  border-radius: 10px !important;
  border: 1px solid #484747;
  color: #bdbdbd;
    font-size: 13px;
    top: 50px
}
.show.dropdown > div a{
  color: #fff;
}
button#pagination-previous-page,
button#pagination-next-page {
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  border-radius: 5px;
  color:#fff;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0 !important;
  height: 30px;
  border-radius: 7px; margin-left: 5px;
  width: 83px !important;
}
.rdt_Pagination > div > div {
  display: none;
}
#pagination-previous-page[disabled],
#pagination-next-page[disabled]{
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  border-radius: 5px;
  color:#fff;
}
#pagination-first-page,
#pagination-last-page{
  display: none;
}
.seeall a{
  color: gray !important;
}
/* .transsec .rdt_TableRow div:first-child,
.transsec .rdt_TableHeadRow div:first-child{
  max-width: 100px;
  width: 100px;
} */
/* .transsec .rdt_TableRow div[data-column-id="1"], .transsec .rdt_TableHeadRow div[data-column-id="1"] {
  max-width: 167px;
}
.transsec div[data-column-id = "4"] {
  min-width: 204px;
}  
div[data-column-id="3"],div[data-column-id="3"].rdt_TableCell > div{
  min-width: 365px;
}
div[data-column-id="2"] {
  min-width: 225px;
}
.transsec div[data-column-id = "5"] {
  min-width: 209px;
}
.transsec div[data-column-id = "6"]{
  
  min-width: 110px;
}
.transsec div[data-column-id = "4"] {
min-width: 189px;
}
.transsec div[data-column-id = "5"] {
min-width: 157px;
} */
.seeall a:hover{
  background-color: transparent !important;
}
.show.dropdown > div a:hover,
.show.dropdown > div a:focus{
  background-color: #000000;
}
.show.dropdown > div.themescroll a:hover{
  background-color: #000000;
}
li.nav-item button {
  min-width: 100px;
}
.wizardinput input:focus, select:focus, textarea:focus {
  background-color: #3f3f3f !important ;
  box-shadow: none !important;
  color: #fff !important;
}
.rr select option{
  background: transparent !important;
}

.showappeare select:focus{
  /* background-color: #fff !important; */
  /* color: #bdbdbd !important; */
}
.codecopy img {
  width: 15px;
  margin-right: 10px;
}
.wizardinput input{
  color: #fff !important;
}
.logins .btn-link:hover {
  color: #fff;
}
.graybg.balance {
  padding: 15px;
  background: #424242;
  text-align: center;
  margin-top: 19px;
}
.txtyellow{
  color: yellow;
}
.f-14{
  font-size: 14px;
}
.f-12{
  font-size: 12px;
}
.f-25{
  font-size: 25px;
}
/* h3.headfont {
  font-size: 30px !important;
}
h2.headfont{
  font-size: 36px !important;
} */
.d-flex.flex-1 > div {
  flex: 1;
}
 .rightinput .input-group-text{
  /* font-size: 22px; */
  color: #A7A7A7;
}
.jc-between{
  justify-content:  space-between;
}
.footer ul li a:hover {
  color: #f5e600;
  cursor: pointer;
}
.jc-center{
  justify-content: center;
}
.page{
  display: flex;
  width: 100%;
 
  /* padding-top: 50px; */
}
.form-check-input:checked {
  background-color: #7f838900;
  border-color: #ababab;
}
.form-check-input {
  background-color: #fff0;
  border-color: gray !important;
}
.dashboard nav.navbar, nav.navbar {
  position: fixed;
  /* height: 45px; */
  width: 100%;
  z-index: 999;
}
.page .sidebar {
  width: 200px;
  background-color: #2322223B;
  min-height: 100vh;
  position: fixed;
}
.sidebarlist{
  height: calc(100vh - 320px);
    overflow-y: auto;
}

.sidebar ul{
  padding-left: 0;
}
.codecopy, .codecopy button{
  font-size: 14px;
}
 .sidebarlist li a {
  text-align: left;
  height: 46px;
  margin-bottom: 1px;
  padding-left: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 9;
  color:#fafafa;
  font-size: 14px;
}
.profile .input-group input {
  background: transparent;
  border-right: 0;
  color: #fff;
}
.coininput{
  max-width: 120px;
}
.cryptfiatlist img {
  width: 30px;
  border-radius: 50%;
}
.profile .input-group .input-group-text{
   background-color: transparent;
   border-left: 0;
   color: gray;
}
.selectbank button, .selectbank button:hover,
.selectbank button:focus,.selectbank button:active,
.selectbank .show>.btn-primary.dropdown-toggle {
  background: #fff;
  color: #000;
  box-shadow: none;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.sidebar ul li:hover button img,
.sidebar ul li a.active button img{
  filter: invert(0);
}

.sidebarlist li a:after {
  background: transparent;
}
.sidebar ul li button{
  background: transparent;
  border: 0;
  color: #fff;
}
.sidebar ul li{
  margin-bottom: 5px;
}
.sidebar ul li button img , .mobilesidebar button img{
  /* width: 29px; */
  max-width: 15px;
  margin-right: 10px;
  /* filter: invert(0.4); */
}
.tabtable.potfolio button.btn.btn-outline-success {
  color: #65a819;
  border-color: #65a819;
}
.tabtable.potfolio button.btn.btn-outline-success:hover {
  background-color: #65a819;
  color: #fff;
}
.notification .d-flex img {
  width: 50px;
  height: 50px;
}
.sidebar ul li a
{
  position: relative;
}
.sidebarlist li:hover a.active:after,
.sidebarlist li:hover a:after,
.sidebarlist li a.active:after {
  content: "";
    /* background: #be8ef1; */
    height: 8px;
    width: 30%;
    position: absolute;
    filter: blur(5px);
    border-radius: 21px;
  
    right:50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    top: 45%;
    left: 75px;
    right: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    /* background: linear-gradient(90deg, #957bfe 22%, #c49efb 70%, #91d3f3 105%); */
    background: linear-gradient(254deg, #316584 0%, #72579D 100%);
    /* background: transparent linear-gradient(93deg, #4A34CF 0%, #82E3F1 50%, #C999FC 100%) 0% 0% no-repeat padding-box; */
}
/* .sidebar ul li.active, .sidebarlist li a.active {
  content: "";
    background: #be8ef1;
    height: 23px;
    width: 100%;
    position: absolute;
    filter: blur(10px);
    border-radius: 21px;
    left: 0;
    z-index: -1;
  background:linear-gradient(90deg, #957bfe 22%, #c49efb 70%, #91d3f3 105%);
} */
.showhead{
  display: none;
}
.navbar-brand{
  min-width: 200px;
    background: #030303;
  margin-right: 0;
  border-right: 1px solid #838383;

}
.content .nav.nav-tabs {
  justify-content: center;
  /* max-width: 383px; */
  margin: auto;
  /* flex: 1; */
}
.content .nav.nav-tabs li{
  flex:1
}
.nav.nav-tabs li.nav-item:first-child button {
  border-radius: 13px 0 0 0;
}
.nav.nav-tabs li.nav-item:last-child button{
  border-radius:  0  13px 0 0;
}
.exchagehead{
  font-size: 29px;
}
.navbar-collapse.collapse, .dashboard nav.navbar {
  background: #030303 !important;
  white-space: nowrap;
  border-bottom: 1px solid #838383;
}
.page .content{
  width: calc(100% - 200px);
  min-height: 100vh;
  background: #030303;
  padding-left: 200px;
  width: 100%;
  /* background-image: url(../src/Assets/Images/bg.png); */
  background-size: 100%;
  background-position: center;
}

.showwater {
  position: relative;
  z-index: 2;
}
.showwater.crypt  p.watermark{
  right: 125px;
}
.deposittabs select,.deposittabs select:hover,.deposittabs select:focus {
  white-space: break-spaces !important;
  padding-right: 35px;
  height: auto;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color:#bdbdbd;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 42px;
}
 .fa-chevron-down.text-light-white:before
{
  color:#bdbdbd !important;
  font-size: 12px !important;

}
p.watermark {
  position: absolute;
  font-weight: 700;
  z-index: -1;
  transform: rotate(-90deg) scale(10);
  right: 133px;
  bottom: 0;
  top: 0;
  line-height: 1;
  margin: auto;
  height: 0;
  color: #25242e;
}
.notecont span {
  line-height: 1.5;
  margin-right: 5px;
}
.conts {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.notecont p {
  font-size: 13px;
    line-height: 25px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    color: #a2a2a2;
    line-height: 1.5;
}
.box {
  /* background: linear-gradient(180deg, #21202a, #21202a); */
  /* background: linear-gradient(180deg, #21202a73, #21202a8c); */
  /* background: linear-gradient(90deg, #0e0e0ec7, #0e0e0ec7); */

  background: linear-gradient(90deg, #14141440 , #0f0f11b5);
  padding:35px 15px 15px 15px;
  border: 1px solid #484747;
  border-radius: 0px;
  /* margin: 10px; */
  /* aspect-ratio: 2.5/1; */
}
.box.darkbox{
  /* background: #1a1a1a;  */
  background: linear-gradient(180deg, #21202a, #21202a);

}
.mobileicons{
  display: none !important;
}
.hoverbox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border:1px solid #484747;
  cursor: pointer;
}

.dashbox .hoverbox img {
  height: 60px;
  width: 60px;
  /* position: absolute;
  top: -22px;
  left: 0;
  right: 0;
  margin: auto; */
}

.hoverbox:hover
{
  background: linear-gradient(254deg, #316584 0%, #72579D 100%);
  /* background: linear-gradient(90deg, #957bfe 22%, #c49efb 70%, #91d3f3 105%); */
}
.hoverbox.box.p-1
{
  padding: 1px !important;
}
.hoverbox:hover .inner_hover_box
{
  background: linear-gradient(90deg, #12151c, #12151c);
}
.hoverbox button:focus {
  box-shadow: none;
}
.chartbox canvas {
  /* height: 400px !important; */
  width: 400px !important;
}

.bal {
  background: #2a2a2a;
  font-size: 14px;
  padding: 6px 18px;
  max-width: 195px;
  text-align: center;
  height: 35px;
  border-radius: 5px;
  line-height: 1.9;
}
.headdrop >div {
  background: #202020;
  min-width: 90px !important;
}
.headdrop > div a{
  color: #fff;
}
.headdrop a:hover{
  background:transparent;
  color: #fff;
}
.noshrink {
  flex-shrink: 0;
}
.noshadow input:focus{
  box-shadow: none;
}
.withdrawselect select {
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  border-radius: 0px;
  color: #bdbdbd;
  font-size: 13px !important;
  padding: 5px;
  height: 42px;
}
.showappeare  {
 position: relative;

}

select.form-control
{
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  appearance: auto !important;
}
.showappeare span{
  position: absolute;
  right: 15px;
  top:-5px;
  bottom: 0;
  margin: auto;
    height: 10px;
    z-index: 1;
    display: none;
}
.withdrawselect.showappeare span {
  color: #fff !important;
}
.withdrawselect.showappeare select:focus{
  background-color: #3f3f3f !important;
    box-shadow: none !important;
    color: #fff !important;
}
.btn.btn-theme {
  background-image: url(../src/Assets/Images/btn.svg);
background-color: transparent !important;
border:none !important;
background-size: 100% 100%;
background-repeat: no-repeat;
border-radius: 0px !important;
color: #c2bcbc !important;
font-size: 12px !important;
font-weight: 400 !important;
text-transform: uppercase !important;
font-family: "poppins";
padding: 8px 24px;
}
.btn_trans_fund_new.btn.btn-theme
{
  background-image: url(../src/Assets/Images/trans_btn_bg.svg);

}
.btn_trans_fund_new.btn.btn-theme:hover
{
  background-image: url(../src/Assets/Images/trans_btn_bg_hover.svg) !important;

}
.btn.btn-theme:hover
{
  background-image: url(../src/Assets/Images/btn_hover_2.svg) !important;
  color:#ccc;

}


.btn_trans_if.btn.btn-theme
{
  background: transparent !important;

  /* background-image: url(../src/Assets/Images/crypto_btn.svg); */

}
.btn_trans_if.btn.btn-theme:hover
{
  background: transparent !important;
  color: #5c9da7 !important;

  /* background-image: url(../src/Assets/Images/crypto_btn_hover.svg) !important; */

}

.nav.nav-tabs li.nav-item button {
  font-size: 22px;
  width: 100%;
}
.dash-bord {
  border: 1px dashed gray;
  padding: 17px;
  margin-bottom: 20px;
  background: #111111;
}
li.nav-item button, li.nav-item button:hover{
  border: 0 !important;
  color: #fff;
}
li.nav-item button.active {
  background: transparent !important;
  border: 0;
  color: #fff !important;
}
li.nav-item button{
  position: relative;
}
li.nav-item button.active:after {
  content: "";
  background: var(--themegrad);
  height: 5px;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.redeem img {
  max-height: 111px;
}
.redeem img.chair {
  max-height: 225px !important;
}
.mw-300{
  max-width: 300px !important;
}
.zarctech .owl-dots {
  text-align: left !important;
}
.btn.btn-theme.small{
  padding: 4px 12px;
  font-size: 12px;
}

.tabtable .rdt_TableCell, .tabtable .rdt_TableCol_Sortable {
  color: #d7d7d7 !important;
  font-size: 13px !important;
}
.acquire button.btn-link.small {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1;
}
.wizardinput select {
  background: #191919;
  border: none;
  border-bottom: 1px solid #191919;
  appearance: auto;
  color: #fff;
  font-size: 14px;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
    padding: 5px;
    height: 42px;
}
.rightlabel span {
  position: absolute;
  right: 10px;
  top: 8px;
  bottom: 0;
  margin: auto;
  color: gray;
}
.wizardinput.rightlabel input {
  padding-right: 78px;
}
.rightlabel div{
  position: absolute;
    right: 48px;
    top: 8px;
    bottom: 0;
    margin: auto;
  color: gray;

}
.rightlabel  {
  position: relative;
}
input[type="text"], select {
  height: 42px;
}
.wizardinput input {
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
    padding: 5px;
    height: 42px;
}
.wizardinput input[type="radio"]
{
  height: unset !important;

}
.wizardinput input::placeholder
{
  color: #bdbdbd;
  font-size: 13px !important;
}
.wizardinput input:focus
{
  border: 1px solid #484747 !important;

}
.selectbox button{
  position: absolute;
  right: 0;
}
.selectbox button:focus{
  box-shadow: none;
}
.selectbox p {
  position: absolute;
  font-size: 10px;
  left: 13px;
  color: #a8a8a8;
  top: 5px;
}
.wizardinput .form-check input {
  background-color: #686868;
  border: 2px solid white !important;
}
.flex-1 > *{
  flex: 1;
}
.viewdetails.content{ 
  max-width: 560px;
margin: auto;
}
.w-full.dropdown button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selecttoken {
  max-width: 122px;
  font-size: 14px;
  font-family: 'maka';
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 46px;
  padding-top: 10px;
  background: #030303EB 0% 0% no-repeat padding-box;
  border: 1px solid #272525;
  z-index: 1;
}
.wizardinput label.form-check-label
{
  font-size: 12px !important;
  font-weight: 400 !important;
}
.selecttoken .form-check {
  margin-bottom: 5px;
}
.selectbox {
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
    padding: 5px;
  height: 44px;
  /* border-radius: 4px; */
}

.selectbox span {
  padding-left: 12px;
  color: #a8a8a8;
}
.deposittabs ul{
  padding-right: 0;
}
.wizardinput .input-group {
  /* border-bottom: 1px solid #262626; */
  padding-bottom: 10px;
}
.wizardinput .input-group .input-group-text {
  background: #191919;
  color: #fff;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border-top: 1px solid #484747;
    border-bottom: 1px solid #484747;
    border-right: 1px solid #484747;
    border-left:none !important;
    border-radius: 0px !important;

}

.wizardinput .input-group input.inpit_without_bor
{
border-right:none !important;
}
.back-btn {
  background: #212121;
  height: 18px;
  width: 18px;
  font-size: 11px;
  padding: 0;
}
select.form-select:focus {
  box-shadow: none;
  
}
 .notify button:hover span.fa , .headdrop a:hover span{
  background: linear-gradient(90deg,  #1c1a1a 28% ,  #1c1a1a 64%,#1c1a1a 86%);
}


.btn,.btn.btn-theme
{
  box-shadow: 0 0 0 0rem rgba(13,110,253,.25) !important;
}

.tabtable .tab-content {
  height: 462px;
  overflow-y: auto;
  padding: 17px;
}
/* .tabtable.potfolio.nohead .rdt_TableHeadRow {
  display: none;
} */
.editprofbtn {
  position: absolute;
    right: 0;
    bottom: 0;
}
.rdt_Pagination span {
  display: none;
}
.rdt_Pagination div:nth-child(2) {
  display: none;
}
.tabtable.potfolio img {
  width: 30px;
  border-radius: 50%;
}
.tabtable.potfolio .rdt_TableCell {
  min-width: 100px;
}
.tabtable.potfolio button {
  padding: 3px;
  font-size: 11px;
  text-transform: uppercase;
  min-width: 84px;
}
.samewidth{
  min-width: 84px !important;
}
.trans .tabtable.potfolio button {
  min-width: unset;
}
.dashsec .tabtable.potfolio .rdt_TableCell span.green,
.dashsec .tabtable.potfolio .rdt_TableCell span.gray,
.dashsec .tabtable.potfolio .rdt_TableCell span.red,
.transsec .rdt_TableRow .rdt_TableCell:first-child span{
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
  border-radius: 3px;
}
.notecont ul{
  padding-left: 0;
}
.notecont li {
  list-style-type: none;
}
.notecont p img {
  width: 14px;
  margin-right: 8px;
  height: 14px;
  margin-top: 5px;
}
.tabtable.potfolio .rdt_TableCell span.red{
  background: #EB4848;
}
.tabtable.potfolio .rdt_TableCell span.green{
  background: #65A819;
}
.tabtable.potfolio .rdt_TableCell span.gray{
  background: #989696;
}
.tabtable.potfolio .fa.fa-arrow-down.green{
  transform: rotate(40deg);
}
.tabtable.potfolio .fa.fa-arrow-down.red{
  transform: rotate(-40deg);
}
.rdt_Pagination {
  background: transparent !important;
  color: #808080 !important;
}
.rdt_Pagination button svg{
  fill: #808080;
}
.btn-filter{
  color: #fff;
  border: 0;
  font-family: "poppins";
}
.exchange .input-group input{
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  border-radius: 0px;
  color: #bdbdbd;
  font-size: 13px !important;
  padding: 5px;
  height: 42px;
  box-shadow:0 0 0 0rem rgba(13,110,253,.25) !important;
}
.exchange .input-group input::placeholder
{
  color: #bdbdbd;
  font-size: 13px !important;
}
.rightinput .input-group button{
margin-left: 15px !important;
} 
.rightinput .input-group-text {
  background: #2C2B2B;
  border-color: #2C2B2B;
  border-radius: 0 8px 8px 0 !important;
}
.exchange .input-group button{
  border-radius: 8px !important;

}
.dropdown-toggle img {
  width: 25px;
  height: 25px;
}
.exchange .dropdown-toggle{
line-height: 2;
}
.exchange .input-group button{
  position: relative;
    /* border:1px solid #fff; */
    border-radius: 0px;
    background-color: transparent !important;
    /* background-image: url(../src/Assets/Images/btn_bg.png); */
    background-image: url(../src/Assets/Images/btn.svg);

    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* clip-path: polygon(0.5em 0, 100% 0, 100% calc(100% - 0.5em), calc(100% - 0.5em) 100%,0 100%, 0% 0.5em); */
    padding-left: 25px;
    padding-right: 25px;
    border:none !important;
  /* font-size: 30px; */
}
.exchange .input-group div {
  background: #686868;
  transform: translate(99px, 0px) !important;
  min-width: 100px !important;
}
.exchange .input-group div img {
  width: 17px;
  margin-right: 5px;
}
.exchange .input-group div a{
  color: #fff;
}
.exchange .input-group div a:hover {
  background: #000000;
}
.exbtn {
  width: 50px;
  height: 50px;
  /* background: #2C2B2B; */
  background: transparent linear-gradient(180deg, #4A34CF 0%, #251A68 100%) 0% 0% no-repeat padding-box;
  /* margin: auto; */
  /* margin-top: -75px !important; */
    margin-left: auto;
    margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 10px auto;
  /* transform: rotate(90deg); */
  /* position: absolute;
  z-index: 2;
  top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%,100%); */
}

.exchange .input-group button:focus{
  box-shadow: none !important;
}
.btn-filter:hover, .btn-filter.active{
  color: #fff;
  background: var(--themegrad);
  font-family: "poppins";
}
.profile .profimg {
  height: 155px;
  aspect-ratio: 1/1;
  border-radius: 6px;
}
.profile span.fa.fa-pencil {
  position: absolute;
  bottom: -5px;
  right: -9px;
  color: #000;
  background: #fff;
  font-size: 13px;
  padding: 5px;
  border-radius: 4px;
}
.tabtable .rdt_TableHeadRow, .tabtable .rdt_TableRow,
.tabtable .rdt_Table {
  background: transparent;
  color: #fff;
  border-color:#313131;
  border:none !important;
}
.tabtable .nav-tabs li button{
  border: 0;
  margin-right: 5px;
  color: #fff;
}
.tabtable .nav-tabs li button.active,.tabtable .nav-tabs li button:hover {
  background: linear-gradient(180deg, #8572ff 20%, #8572ff 80%) !important;
  border-radius: 8px;
  border-color: transparent !important;
  color: #fff;
  border:0
}

.neonomad {
  position: absolute;
  bottom: 0;
  padding: 14px;
  /* background: linear-gradient(7deg, #363252, #1a1a1a); */
  padding-bottom: 64px;
  text-align: center;
}
li.neonomad img {
  width: 100%;
  max-width: 120px !important;
}
li.neonomad a.btn {
  text-align: center;
  width: 127px;
  line-height: 2;
  align-items: center;
    display: flex;
    justify-content: center;
}
marquee img {
  width: 30px;
}
.bggrad {
  position: absolute;
  /* background:linear-gradient(90deg, #957bfe 13%, #c49efb 31%, #91d3f3 77%); */
  background: transparent linear-gradient(254deg, #316584 0%, #72579D 100%) 0% 0% no-repeat padding-box;
  opacity: 0.25;
  filter: blur(50px);
  /* background: linear-gradient(90deg, #1f2029 13%, #27363f 31%, #91d3f3 77%); */
  max-width: 700px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  max-height: 86vh;
  /* filter: blur(233px); */
  /* right: 0; */
  top: 100px;
  left: 204px;
  /* z-index: -1; */
}
.bggradleft
{
  position: absolute;
  background: linear-gradient(99deg, #19151f 22%, #16111e 80%, #4d81b9 85%);
  max-width: 300px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  filter: blur(100px);
  left: 95px;
  bottom: 100px;
  content: "";
  z-index: 1;
}
.bggradright {
  position: absolute;
  background: transparent linear-gradient(254deg, #316584 0%, #72579D 100%) 0% 0% no-repeat padding-box;
opacity: 0.28;
filter: blur(50px);
  max-width: 300px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  right: 0;
  bottom: 100px;
  content: "";
}
.tabtable .nav-tabs{    border-bottom: none;}


.tabtable .tab-content::-webkit-scrollbar,
.themescroll::-webkit-scrollbar ,
.sidebarlist::-webkit-scrollbar ,
.portscroll.themescroll div::-webkit-scrollbar,
.rdt_Table::-webkit-scrollbar,
.aquacard_heh::-webkit-scrollbar
  {  width: 0.5em;height: 5px;
  background-color:  #a889fd;
}
body::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:vertical,
.tabtable .tab-content::-webkit-scrollbar-thumb,
.themescroll::-webkit-scrollbar-thumb,
.sidebarlist::-webkit-scrollbar-thumb,
.portscroll.themescroll div::-webkit-scrollbar-thumb,
.rdt_Table::-webkit-scrollbar-thumb,
.aquacard_heh::-webkit-scrollbar-thumb {
height: 5px;
  background-color:  #c2d2e4;
}



.themescroll.hidescroll::-webkit-scrollbar{
  width: 0;
}
.themescroll, .sidebarlist,.tabtable .tab-content,.portscroll.themescroll div,
.rdt_Table, body,html,.dropdwo_height_Scrol .dropdown-menu,*,
.aquacard_heh
{
  scrollbar-color: #a889fd #c2d2e4 !important;
  scrollbar-width: thin !important;
  
}


.tabtable .nav-tabs {
  border-bottom: none;
  border-bottom: 1px solid #565656;
  padding: 19px;
}
.themeinput button.btn-link,.acquire button.btn-link.small {
  background: gray;
  color: #fff;
  border-radius: 30px !important;
  text-decoration: none;
}
/* .selectbank > div:first-child {
  background: gray;
  width: 95px;
  height: 94px;
  text-align: center;
  border-radius: 7px;
  padding-top: 17px;
  margin-right: 10px;
} */
.selectbank > div:first-child input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  opacity: 0;
}
.selectbank > div input::placeholder{
  color: #fff;
}
.selectbank > div:first-child input:focus, .selectbank > div input:focus-within {
  outline: 0 !important;
}
.selectbank > div input {
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
  padding: 5px;
}
.selectbank > div input::placeholder
{
  color: #bdbdbd;
  font-size: 13px !important;
}

.selectbank > div:first-child img {
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
}
.landing img{
  max-width: 100%;
}
.landhead{
  border-bottom: 2px solid #2e2e2e;
}
.banner h1{
  font-size: 50px;
}
.banner h1 span{
  font-size: 40px;
}
.landhead img {
  height: 39px;
}
.banner .owl-dots {
  margin-top: 40px !important;
}
.bordbottom {
  border-bottom: 1px solid #565656;
}
img.pencil {
  width: 17px;
}
.thememodal .modal-content{
  background-color: #000000e3;
  border-radius: 0px;
  box-shadow: -6px -4px 45px -3px #090909;
  /* border: 1px solid white; */
}
.apexcharts-toolbar {
  display: none !important;
}
.modal-backdrop.show {
  background: #000000;
  opacity: 0.5;
}

.apexcharts-xaxistooltip, .apexcharts-yaxistooltip{
  color: transparent !important;
  fill: #fff;
  background: #ffffff00 !important;
  border: transparent !important;

  opacity: 1;
}
.apexcharts-xaxis-texts-g text,
.apexcharts-yaxis-texts-g text {
  fill: #fff;
}
.apexcharts-legend-text {
  color:#fff !important;
}

.apexcharts-tooltip-series-group.apexcharts-active, .apexcharts-tooltip-series-group:last-child,
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title ,
.apexcharts-tooltip.apexcharts-theme-light{
  background: #21202a !important;
}
a.navbar-brand img {
  height: 38px;
  padding-left: 15px;
}
.editimg{
  position: relative; margin-right: 15px;
}
.editimg img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
 
}
.editimg img.round_small_img
{
  height: 50px;
width: 50px;
border-radius: 50%;
border: 1px solid #484747;
padding: 5px;
}
.editimg button {
  position: absolute;
  background: #5466d1;
  border: none;
  padding: 4px;
  font-size: 10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: 0;
}
.editimg button input{
  width: 25px;
  border-radius: 50%;
  height: 25px;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
}
.transsec .rdt_TableRow,.transsec .rdt_TableHeadRow {
    border-bottom: 1px solid #424446 !important;
    /* border-bottom: none !important; */
    border-radius: 0 !important;
}
.profbox img {
  /* background: #a184ef; */
  object-fit: contain;
  overflow: hidden;
  max-width: 143px;
  aspect-ratio: 1/1;

}
.themeinput input ,.themeinput input:focus{
  background: transparent;
  border: 0;
  border-bottom: 1px solid #262626;
  border-radius: 0;
  padding-left: 0;
  box-shadow: none;
  color: #fff;
}
.themeinput input::placeholder{
  color: #fff;
}
.themeinput label{
  font-size: 14px;
  color: gray;
}
.preference img {
  height: 80px;
  width: 80px;
}
input::placeholder {
  font-size: 15px;
}
.ciclechart  span{
  position: absolute;
    bottom: -36px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.circlebtn{
  background: linear-gradient(180deg, #3750ea 20%, #223291 80%) !important;
  border-radius: 50%;
  color: #fff;
  height: 40px;
  width: 40px;
}
.headicon {
  background: var(--themegrad);
  color: #000;
  height: 26px;
  width: 26px;
  text-align: center;
  padding: 7px;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
}
.headicon span {
position: absolute;
background: #0a8654;
    height: 8px;
    width: 8px;
/* background: #fff;
height: 13px;
width: 13px; */
border-radius: 50%;
font-size: 10px;
display: flex;
align-items: center;
justify-content: center;
right: 0px;
top: 0;
}
.circlebtn:hover{
  color: #fff;
}
.history .rdt_TableBody .rdt_TableRow {
  background: transparent;
  border-radius: 5px;
  margin-bottom: 4px;
  margin-top: 4px;
  color: #fff;
}
.history .rdt_Table{
  background: transparent !important;
}
.h-55{
  height:55px
}

.rdt_TableRow .rdt_TableCell {
  color: #d7d7d7 !important;
    font-size: 13px !important;
}

.plans {
  padding: 15px;
  background: #2e2e2e;
  border-radius: 15px;
}
.bordbox {
  border: 1px solid gray;
}
.bordbox.redbox {
  background: #ff00002b;
  border-color: #780a0a;
}
.bank-det table tr td {
  padding-left: 10px;
  background: #393939;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mobilesidebar ul {
  padding: 0;
}
.mobilesidebar ul li{
  list-style-type: none;
}
.mobilesidebar ul li a{
  padding-left: 0;
}
.mobilesidebar .sidebarlist li a button {
  background: transparent;
  color: #fff;
  border: 0;
}
.bank-det table tr{
  border: 1px solid #000;
}
.form-switch input,.form-switch .form-check-input:focus
.form-switch .form-check-input:checked{
  background-color: transparent !important;
  border-color: #292727 !important;
  height: 26px;
  width: 45px !important;
}
.qrcode {
  max-width: 125px;
  /* margin: auto; */
  background: white;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
}
.qrimg{
    max-width: 150px;
}
button.regbtn {
  background: #3750e9;
  color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 20px;
  border: 0;
  z-index: 9;
}
.browse{
  position: relative;
  font-size: 14px;
  width: 163px;
}
.browse input{
  width: 50px;
  opacity: 0;
}
.browse button{
  font-size: 14px;
    position: absolute;
    padding: 0;
    right: 0;
pointer-events: none;
}
.fa-cloud-upload{
  font-size: 30px;
  margin: 15px;
}
.loginmodal .modal-dialog-scrollable .modal-content{
  overflow: visible !important;
}
.uploads img {
  width: 80px;
  height: 80px;
  padding: 10px;
  background: #252525;
  border-radius: 5px;
}
.closebtn {
  max-width: 40px;
}
.uploads {
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  border-radius: 0px;
  color: #bdbdbd;
  font-size: 13px !important;
  padding: 10px;
}
.closebtn .bg-disabled {
  background: #252525 !important;
  border: none;
}
.closebtn .bg-white {
  background: #fff !important;
  border: none;
}
.uploadfiles .progress {
  border-radius: 14px;
  height: 6px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.uploadfiles .circlebtn {
  height: 30px;
  width: 30px;
}
span.fa.fa-check.circle {
  font-size: 18px;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 15px;
}
.wizard{
  display: flex;
  justify-content: space-between;
}
.wizard > div{
  flex: 1;
  position: relative;
  z-index: 9;
}
.wizard > div:first-child{
  text-align: left;
}
.wizard > div:last-child{
  text-align: right;
}
.wizard.text-center .circlebtn.disable {
  background: gray !important
}
.prog1:after,.prog2:after,.prog2:before,.prog3:before{
  content: "";
  background: gray;
  height: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.prog1:after,.prog3:before,.prog2:after,.prog2:before{
  margin: auto;
}
.prog2:after,.prog2:before{
  width: 50%;
}
.prog1:after , .prog3:before {
  width: 100%;
}
.prog2:before,.prog3:before{
  left: 0;
}
.prog2:after{
  right: 0;
}
.prog1.progres:after,.prog2.progreshalf:before,.prog2.progresfull:before
,.prog2.progresfull:after,.prog3.progreshalf:before {
  background: #3a55f8;
}
.prog2:after, .prog2:before {
  width: calc(50% - 40px);
}
.prog1:after{
  width: calc(100% - 58px);
  right: 0;
}
.prog3:before{
  width: calc(100% - 58px);
  left: 0;
}
.confirm{
  display: flex;
  gap: 15px;
}
form.confirm input{
  display:inline-block;
  width:50px;
  height:50px;
  text-align:center;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #c8c8c8;
}
form.confirm input.otp:focus{
  box-shadow: none;
}
.landing{
  background: linear-gradient(357deg, #222222, #040404);
}
.mobileicons .nav-item.dropdown a:after {
  display: none;
}
.togglebtn span.fa.fa-bars.pe-3 {
  font-size: 21px;
}
.plans table th:nth-child(2),.plans table tr td:nth-child(2) {
  background: var(--landtheme);
  color: #000;
}
.upper{
  text-transform: uppercase;
}
.plans table th:nth-child(2),.plans table th:nth-child(3),
.plans table th:nth-child(4){
  border-radius: 15px 15px 0 0;
}
.coindesign li{
  list-style-type: none;
  display: flex;
  align-items: flex-start;
}
.coindesign li img{
  height: 14px;
  margin-top: 5px;
}
.dash-bord img {
  width: 30px;
  height: 30px;
  max-width: 30px;
  margin-bottom: 15px;
}
.footer{
  background-color: #030303;
  padding-top: 15px;
  padding-bottom: 15px;
}
.footer ul li {
  display: inline-block;
  padding-right: 7px;
  font-size: 14px;
}
.footer ul {
  padding-left: 0;
}
.footer li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.plans table th:nth-child(3),.plans table tr td:nth-child(3),
 .plans table th:nth-child(4),.plans table tr td:nth-child(4) {

  background: #363636;
}
.plans table th:nth-child(3),.plans table tr td:nth-child(3),
 .plans table th:nth-child(4),.plans table tr td:nth-child(4),
 .plans table th:nth-child(2),.plans table tr td:nth-child(2){
  min-width: 200px;
  text-align: center;
 }
 .plans table tbody tr:first-child td:nth-child(1) {
  min-width: 219px;
}
.plans table{
  border-spacing: 44px 0;
  border-collapse: separate !important;

}
.plans table tr:last-child td:nth-child(2),
.plans table tr:last-child td:nth-child(3),
.plans table tr:last-child td:nth-child(4){
  border-radius: 0 0 15px 15px;
}
.plans tbody tr:first-child td:first-child {
  color: gray;
  font-weight: 600;
  font-size: 20px;
}
.plans tr td {
  font-size: 18px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.plans table tbody tr:first-child td:nth-child(2), .plans table tbody tr:first-child td:nth-child(3), .plans table tbody tr:first-child td:nth-child(4) {
  font-size: 22px;
  font-weight: 500;
}
.landing button.owl-dot span{
  height: 8px !important;
  width: 8px !important;
}
.landing button.owl-dot.active span {
  background: var(--landtheme) !important;
  width: 57px !important;
}
.statebox{
  background: #141414;
}
.status h4 {
  font-size: 40px;
}
.loginmodal{
  cursor: url(./Assets/Images/close.png), auto
}
button.btn.close {
  font-size: 26px;
  position: absolute;
  right: 11px;
  top: 8px;
  z-index: 999;
 color:#444242 !important;
 
 
}
button.btn.close:hover
{
color:#fff;
}

.confirm .otp {
  background: #000;
  color:#fff;
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
    padding: 5px;
    height: 42px;

}
.confirm .otp:focus,.confirm .otp:focus-visible
{
  border: 1px solid #484747 !important;
  outline: -webkit-focus-ring-color auto 0px !important;

}
.loginmodal .modal-content{
  cursor: auto;
}
.donutchart{
  height: 401px !important;
}
.donutchart text{
  fill: #fff;
}
@media(max-width:1199px){
  .deposittabs{
    margin-left: 0;
    margin-right: 0;
  }
  .tabtable .rdt_TableCell, .tabtable .rdt_TableCol_Sortable {
    font-size: 16px !important;
  }
  .plans tr td {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
 }
  /* .plans table th:nth-child(3), .plans table tr td:nth-child(3), 
  .plans table th:nth-child(4), .plans table tr td:nth-child(4), 
  .plans table th:nth-child(2), .plans table tr td:nth-child(2) {
    width: 170px;
  }
  */
}
.form-switch input:hover {
  cursor: pointer;
}
.showin767{
  display: none;
}
.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 0;
  display: none;
}
.modal-dialog-scrollable .modal-body{
  scrollbar-width: none !important ;
}
@media(max-width:1199px){
  .redeem img.chair {
    max-height: 200px !important;
  }
}
@media(max-width: 991px){
  .redeem img.chair {
    max-height: 200px !important;
}
.redeem img.chair {
  max-height: 200px !important;
}
  .page .sidebar, .navbar-brand {
    width: 180px;
  }
  .page .content {
    padding-left: 180px;
  }
  .status h4 {
    font-size: 30px;
  }
  .banner h1 {
    font-size: 38px;
  }
  .banner h1 span {
    font-size: 30px;
  }
  .banner p,.zarc p,.zarctech p, .zarcaims p{
    font-size: 14px;
  }
}
@media(max-width: 767px){
  .neonomad
  {
    text-align: left !important;
  }
  .bggradleft
  {
    left: unset !important;
    right: 0px !important;
  }
  .bggrad
  {
    display: none !important;
  }
  .h-55.navbar
  {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-bottom: 1px solid #838383;
  }
  .h-55.navbar .navbar-brand
  {
    border-right: 1px solid #838383;
    padding-right: 10px;
  }
  .h-55.navbar .navbar-brand img
  {
    padding-left: 10px;
  }
  .hidein767 {
    display: none;
  }
  .sidebarlist {
    min-height: calc(100vh - 100px);
}
.neonomad {
  position: unset;
  margin-bottom: 15px;
}
  .neonomad img {
      max-width: 125px;
  }
  .page .content {
    padding-left: 0px;
  }
  .content.redeem {
    padding-top: 20px;
}
  .page {
    padding-top: 0px;
}
  .exchange .input-group div{
    transform: translate3d(0px, 48px, 0px) !important;
  }
  .transscroll{
    width: 100%;
    text-align: right;
    overflow: scroll;
    white-space: nowrap;
    margin-left: 17px;
  }
  /* .h-55.navbar {
    height: 65px;
} */
  .showin767{
    display: block;
  }
  .mobileicons{
    display: flex !important;
  }
  .banner h1 {
    font-size: 30px;
}
  img.sideimage {
    height: 280px;
    width: auto !important;
}
  .hidehead{
    display: none;
  }
  .showhead{
    display: block;
  }
  .status h4 {
    font-size: 24px;
   }
   .status p {
    font-size: 14px;
   }
  .logobg{
    background: transparent !important;
    border-right: 0;
  }
  .box {
     aspect-ratio: unset; 
  }
  
  .sidebarlist li a.active{
    border-radius: 0;
  }
  .dnone-767 {
    display: none;
}
  .navbar-brand{
    width: unset;
  }
  .togglebtn {
    background: transparent;
    border: none;
    color: #fff;
    padding: 0;
  }
  .mobilesidebar .sidebarlist li a button {
    background: transparent;
    color: #fff;
    border: 0;
  }
  .navbar-dark .navbar-brand {
    background: #212529;
}
  .navbar-brand img {
    height: 37px;
}
  .page .sidebar {
    display: none;
  }
  .page .content {
    width: 100%;
  }
  .mobilemenu{
    position: absolute;
    min-height: 100vh;
    top: 54px;
    width: 100%;
    z-index: 999;
    background: #000;
    padding: 0 20px;
  }

.mobilemenu .dropdown-menu {
    background: #000;
}
.mobilemenu .dropdown a{
  color: #fff !important;
}
}

@media(max-width:575px){
  .choosenet.choosenet_hover div:hover::before
  {
    top: -37px !important;
    right: 0px !important;
  }
  .modal_back_image
  {
    background-size: cover;
  }
  /* .exbtn
  {
    transform: translate(-50%,180%) !important;
  } */
  .selectbank.d-flex
  {
    display: block !important;
  }
  .pos_ab_note
  {
    width: 130px !important;
    /* margin-top: 10px !important; */
  }
  .pos_show_rel
  {
    margin-top: 10px !important;
  }
  .border_right_note {
    border-bottom: 1px solid #202020;
    border-right: 0px !important;
}
.bg-black
{
  height: 50px !important;
}
  .depositpage .headfont{
    white-space: unset !important;
    word-break: break-word;
  }
  .apexcharts-xaxis-texts-g text,
.apexcharts-yaxis-texts-g text {
font-size: 10px;
}
  .navbar-brand{
    min-width: unset;
  }
  .bal {
    font-size: 12px;
    padding: 6px 11px;
    line-height: 2;
}

  .exchagehead{
    font-size: 18px;
  }
  .btn.btn-theme {
    font-size: 16px;
}
  /* .codecopy, .codecopy button {
    font-size: 15px;
}
  h3.headfont {
    font-size: 20px !important;
} */
/* .nav.nav-tabs li.nav-item button {
  font-size: 25px;
} */
/* .notecont p {
  font-size: 15px;
} */
.upload input[type="text"] {
  height: 35px;
}
.selectbank > div:first-child {
  /* height: 79px; */
  padding-top: 12px;
  margin-right: 0px;
}

  .tablescroll {
    height: 73%;
}
  .trans .d-flex h3.headfont {
    font-size: 16px;
}
  .redeem img.chair {
    max-height: 140px !important;
}
  .redeem img {
    max-height: 95px;
  }
  .tab-content .mw-300{
    max-width: 90% !important;
  }
  g.donutchart-legend{
    display: none;
  }
  .landhead img {
    height: 33px;
}
  .status h4 {
    font-size: 20px;
  }
  .uploads img {
    /* width: 40px;
    height: 40px;
    padding: 6px; */
  }
 
  button.regbtn {
    right: 7px;
  }
  .wizard button.btn.circlebtn {
    height: 25px;
    width: 25px;
    line-height: 1;
    font-size: 12px;
    padding: 0;
  }
  .prog1:after, .prog2:after, .prog2:before, .prog3:before {
    height: 3px;
  }
  .prog1:after,.prog3:before  {
    width: calc(100% - 36px);
  }
  .prog2:after, .prog2:before {
    width: calc(50% - 22px);
  }
}

@media(max-width:460px){
  .chartbox canvas {
    height: 230px !important;
    width: 230px !important;
}
}
@media(max-width:425px){

  .viewdetails.content li.nav-item button {
    font-size: 18px;
}
  .tab-content .mw-300 {
    max-width: 96% !important;
    font-size: 14px;
}
  img.sideimage {
    height: 200px;
  }

  .navbar-brand img {
    height: 30px !important;
  }
  form.confirm input {
    width: 42px;
    height: 42px;
}
.confirm {
  display: flex;
  gap: 3px;
}

}
@media(min-width:757px){
  
  .modal-sm {
    max-width: 375px;
  }
  
}

.transsec .rdt_TableRow .rdt_TableCell:last-child div:first-child {
  overflow: visible !important;
}

/* .rdt_TableCell > div, .rdt_TableCell  div:first-child {
  overflow: visible !important;
} */
.history .dropdown button:after {
  display: none;
}
.history .dropdown button span.fa.fa-ellipsis-v {
  transform: rotate(90deg);
  /* color: #65A819 !important; */
  height: 21px !important;
    width: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.history .dropdown button.btn-success span.fa.fa-ellipsis-v {
 color: #65A819 !important
}
.history .dropdown button {
  background-color: #273813;
  border-color: #273813;
}
.history .dropdown button:focus {
  box-shadow: none !important;
  background-color: #273813;
}
.history .dropdown button.red span {
  color: #eb4848 !important;
}
.history .dropdown button.red {
  background-color: #4a2020;
  border-color: #4a2020;
}
.history .dropdown button.gray span {
  color: #989696 !important;
}
.history .dropdown button.gray {
  background-color: #343434;
  border-color: #343434;
}

.history .dropdown > div a {
  font-size: 14px;color: #fff;
}
.history .dropdown > div a:hover {
  background-color: #575656;
}
.history .dropdown > div{
  background: #434343;
  
}



.themeinput input.form-control {
  background-color: #2c2b2b;
  border-color: #2c2b2b;
  border-radius: 8px!important;
  color: #fff;
  height: 45px;
  margin-right: 10px;
  /* appearance: auto; */
}
.themeinput input.form-control{
  padding: 10px;
}
.depositpage .headfont, .exchagehead.headfont{
  /* white-space: nowrap; */
  word-break: break-word;
}

.navbar .balancedrop .dropdown button{
background-color: #383838;
border: none;
}
.navbar .balancedrop .dropdown button{
  background-color: #383838;
  border: none;
  }
  .dashsec .show > .btn-success.dropdown-toggle:focus,.dashsec .show > .btn-success.dropdown-toggle:active{
    box-shadow: none !important;
  }
  .dashsec .btn-check:focus + .btn-success,.dashsec .btn-success:focus{
    box-shadow: none;
    text-transform: none;
  }
  .dashsec .balancedrop .upper{
    text-transform: none;
  }
  .dashsec .balancedrop .dropdown-item span.poppins{
   font-size: 12px;
  }

  /* .balance_drop_corner .dropdown-toggle:after
  {
content: none !important;
  } */
  .navbar .balancedrop .dropdown button
  {
    position: relative;
    /* border:1px solid #fff; */
    border-radius: 0px;
    background-color: transparent !important;
    /* background-image: url(../src/Assets/Images/btn_bg.png); */
    background-image: url(../src/Assets/Images/btn.svg);

    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* clip-path: polygon(0.5em 0, 100% 0, 100% calc(100% - 0.5em), calc(100% - 0.5em) 100%,0 100%, 0% 0.5em); */
    padding-left: 25px;
    padding-right: 25px;
   
  }
  .navbar .balancedrop .dropdown button:hover
  {
    color: #ccc !important;
    background-image: url(../src/Assets/Images/btn_hover_2.svg);

  }
/* 
  .navbar .balancedrop .dropdown button:hover
  {
    transform: translateY(-2px);
    transition: all 0.5s ease-in-out;
  } */

  .navbar .balancedrop .dropdown button::after, .navbar .balancedrop .dropdown button::before
  {
    content: "";
    position: absolute;
    inset: 0;
  }
  /* .navbar .balancedrop .dropdown button::before
  {
    background: #828282;
    z-index: -2;
  }

  .navbar .balancedrop .dropdown button::after
  {
    background: #030303;
    z-index: -1;
    clip-path: polygon(0.25em 0.5em, 0.5em 0.25em, calc(100% - 0.25em) 0.25em, calc(100% - 0.25em) calc(100% - 0.25em - 0.5em),calc(100% - 0.25em - 0.5em) calc(100% - 0.25em), 0.25em calc(100% - 0.25em));
    left: -6%;
    width: 104%;
    top: -3%;
    height: 110%;
  } */

  @media only screen and (min-width:768px)
  {
    .flex_cente_mfd
    {
      display: flex;
      align-items: center;
    }
    #responsive-navbar-nav
    {
      border-left: 1px solid #838383;
    }
    .balancedrop
    {
      position: relative;
      padding-left: 10px;
    }
    .balancedrop:after
    {
      position: absolute;
      content: "";
    
      border-right: 1px solid #838383;
      right: -10px;
      top: -10px;
      width: 10px;
      height: calc(100% + 20px);
    }
    .navbar .notification 
    {
      position: relative;
    }
    .navbar .notification:after
    {
      position: absolute;
      content: "";
      
      border-right: 1px solid #838383;
      right: -10px;
      top: -10px;
      width: 10px;
      height: calc(100% + 17px);
    }
  }

  .navbar .dropdown-toggle::after
  {
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    border:none;
    vertical-align: 0rem;
    color:#828282;
  }
  .balancedrop, .balancedrop button
  {
    color:#aeacac;
    text-transform: uppercase !important;
  }
 .balancedrop button .upper
  {
    text-transform: uppercase !important;
    font-size: 12px !important;
  }
  .headicon.headicon_with_grad
  {
background:transparent !important;
color:#989898 !important;
  }
.headdrop .nav-link
  {
    color: #c0c0c0 !important;
    text-transform: uppercase;
    font-size: 12px;
  }
  .text-left
  {
    text-align: left !important;
  }
  .font_gery_new
  {
    color:#696868 !important;
  }
  .portscroll.themescroll div
  {
  
    color:#d7d7d7 !important;
    font-size: 13px !important;
    background-color: transparent;

  }

  .tab_stl_new .nav-item
  {
    background: linear-gradient(90deg, #0f0f11b5, #0f0f11b5);
    border: 1px solid #484747;
    position: relative;
    margin-right: 10px;
    border-radius: 0px !important;
    }

  .tab_stl_new .nav-item .nav-link:hover,.tab_stl_new .nav-item .nav-link.active {
  background: linear-gradient(254deg, #316584 0%, #72579D 100%);

  border-color: transparent;
   
}
.tab_stl_new .nav-item .nav-link
{
  border-radius: 0px !important;
  background-color: transparent !important;
  color:#c3c3c3 !important;
  font-size: 13px !important;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid transparent;
  padding: 0px;

}
.tab_stl_new .nav-item .nav-link div
{
  padding: 7px 30px;
}
.tab_stl_new .nav-item .nav-link:hover div,.tab_stl_new .nav-item .nav-link.active div
  {
    background: linear-gradient(90deg, #12151c, #12151c) !important;
  }
.modal_back_image
{
  background-image: url(../src/Assets/Images/dashboard_blur_bg.svg);
  min-height: 100vh;
  background-size:cover;
  background-position: center;
  
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .flex_chane_1,.flex_chane_2
  {
    display: block !important;
  }
  .dashbox .hoverbox img
  {
    width: 40px !important;
    height: 40px !important;
  }
  .bggrad 
  {
    max-width: 500px !important;
  }
  .modal-dialog {
    max-width: 500px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .modal_big .modal-dialog {
    max-width: 600px !important;
  }
}


@media only screen and (min-width:992px)
{
  .modal-dialog {
    max-width: 500px !important;
  }
}

@media only screen and (min-width:992px)
{
  .modal_big .modal-dialog {
    max-width: 700px !important;
  }
}


.headfont.headfont_normal
{
  font-family: 'Poppins' !important;
  font-size: 18px;
}
body{
  font-family: 'Poppins';
}
.card_cutout
{
  /* background: linear-gradient(90deg, #0f0f11b5, #0f0f11b5); */
  background-image: url(../src/Assets/Images/swap_page_cutout.png);
  position: relative;
z-index: 3;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 35px 15px 15px 15px;
    /* border: 1px solid #484747; */
    border-radius: 0px;
    /* border:none !important; */
    background-color: transparent !important;
}

.rounded_img_border
{
  border: 1px solid #484747;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #151515;
}
.rounded_img_border img
{
  max-width: 50px !important;
}
.dashboard_tae_data .rdt_TableCell:last-child
{
min-width: 140px !important;
/* min-width: 500px !important; */

}

.dashboard_tae_data .rdt_TableCell:nth-child(3)
{
min-width: 140px !important;
/* min-width: 500px !important; */

}

body::-webkit-scrollbar {
  width: 0.5em;
  background-color:  #a889fd;
}
body::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:vertical {
height: 5px;
  background-color:  #c2d2e4;
}

body{
  scrollbar-color: #a889fd #c2d2e4;
  scrollbar-width: thin;
}
.notecont
{
  padding: 20px 15px 20px 15px;
    border: 1px solid #484747;
    position: relative;
    z-index: 2;
}
.pos_show_rel
{
  z-index: 2;
    position: relative;
}
.pos_ab_note
{
  border:1px solid #202020;
  border-radius: 25px;
  padding: 5px 10px;
  text-align: center;
  width: 110px;
  display: flex;
  align-items: center !important;
    justify-content: center;
    position: absolute;
    left: calc(50% - 0px);
    right: calc(50% - 0px);
    transform: translate(-50%,-50%);
    top: 0px;
    background: #0e0d0d;
}
.border_right_note
{
  border-right: 1px solid #202020;
}
.label_input_font
{
  color:#e6e6e6 !important;
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.bg-black
{
  background:linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  border-radius: 5px;
  height: 42px;
}
.color_grey_theme
{
  color: #a2a2a2;
}
.codecopy
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bg_black_bog_copy
{
  border-radius: 0px !important;
  color: #bdbdbd;
    font-size: 13px;
    padding: 3px 0px;
}
.btn_lin_grey
{
  text-decoration: underline;
  color: #bdbdbd;
  font-size: 13px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

}
.btn_lin_grey:hover
{
  color:#fff;

}
.modal-body .headfont
{
  font-size: 25px;
}
.tab_stye_modal
{
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.btn-trans-link
{
  border:none !important;
  color: #6d6b6b !important;
  font-size: 13px !important;
}
.text_blue_bn_lnk
{
  color:#2aa4b6 !important;
}
.label_input_font_ans
{
  color:#868484 !important;
}
.label_input_font_ans_word_break
{
  word-break: break-all;
}
.acc_number_min_wid
{
  min-width: 135px;
}
.modal-body .notecont 
{
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
}
.modal-body .border_right_note
{
  border-right-color: #4f4d4d !important;
}
.font_trade_grey
{
  font-size: 13px;
    font-weight: 400;
    color: #a2a2a2;
}
.btn-border-only
{
  background: linear-gradient(90deg, #12151c, #12151c);
  border: 1px solid #484747;
  border-radius: 0px !important;
}
.btn-border-only:hover
{

  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
}

.upload_img_div
{
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  border: 1px solid #484747;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 10px;
}

.logut_icon_img
{
  max-width: 15px !important;
}
#responsive-navbar-nav  .show.dropdown > div
{
min-width: 186px !important;
}

.dropdown-menu.show[aria-labelledby="collasible-nav-dropdown"]
{
left: -40px !important;
}

.wizardinput label
{
  color: #e6e6e6 !important;
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
.text-right
{
  text-align: right !important;
}
.border_left_non_inout
{
  border-left: none !important;
}

a.aqua
{
  font-size: 13px !important;
    font-weight: 400 !important;
}
a.aqua:hover
{
  color:#fff !important;
}
.form-check-label
{
  font-size: 13px !important;
  font-weight: 400 !important;
}
.btn_big_theme_border

{
  padding: 15px 35px !important;
}

.navbar  .show.dropdown > div a{
  color: #fff;
  text-transform: uppercase;
  font-size: 11px !important;
  font-weight: 400 !important;
}
@media only screen and (min-width:992px) and (max-width:1199px)
{
  .flex_chane_1
  {
    display: block !important;
  }
  .flex_btn_dowl
  {
    display: flex !important;
    align-items: center;
  }
  .flex_chane_1 .headfont_po
  {
    margin-bottom: 10px !important;
  }
  .dashbox .hoverbox img
  {
    width: 40px !important;
    height: 40px !important;
  }
}
@media only screen and (min-width:576px) and (max-width:767px)
{
  .flex_chane_1,.flex_chane_2
  {
    display: block !important;
  }
  .border_right_note {
    border-bottom: 1px solid #202020;
    border-right: 0px !important;
}
}
@media only screen and (min-width:992px) and (max-width:1140px)
{
.heigt_set_lael_md
{
  min-height: 34px !important;
}
}


.btn_end_style .btn 
{
  margin-left: auto;
  width: auto;
}
.exchagehead
{
  color:#A7A4A4;
  font-size: 13px;
}
.exchange_sec_nut .input-group button
{
  height: 42px;
  font-size: 13px !important;
}
.exchange_sec_nut .input-group button::after
{
  content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    border: none;
    vertical-align: 0rem;
    color: #828282;
}
.exchange_sec_nut .dropdown-toggle img
{
  border-radius: 50%;
}

.dd_inpout_desi .dropdown button:hover
{
  color: #ccc !important;
  background-image: url(../src/Assets/Images/btn_hover_2.svg);

}
.dd_inpout_desi .dropdown-menu
{
  background-color: #202020 !important;
    background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #181b1d 95%);
    border-radius: 10px !important;
    border: 1px solid #484747;
    color: #bdbdbd;
    font-size: 13px;
    top:0px !important;
    right: 0px !important;
}

.dd_inpout_desi .dropdown button
{
  position: relative;
  /* border:1px solid #fff; */
  border-radius: 0px;
  background-color: transparent !important;
  /* background-image: url(../src/Assets/Images/btn_bg.png); */
  background-image: url(../src/Assets/Images/btn.svg);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* clip-path: polygon(0.5em 0, 100% 0, 100% calc(100% - 0.5em), calc(100% - 0.5em) 100%,0 100%, 0% 0.5em); */
  padding-left: 25px;
  padding-right: 25px;
  border:none !important;
  color: #bdbdbd;
  font-size: 13px;
  min-width: 125px;
 
}

.dd_inpout_desi .dropdown-menu a:hover {
  background-color: #000000;
}
.dd_inpout_desi .dropdown-menu img
{
  max-width: 20px;
  border-radius: 50%;
  margin-right: 5px;
}
.input_ex_des input
{
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
    border-radius: 0px;
    color: #bdbdbd;
    font-size: 13px !important;
    padding: 5px;
    height: 42px;
    
    box-shadow: 0 0 0 0rem rgba(13,110,253,.25) !important;
}
.input_ex_des input:focus{
  color: #bdbdbd !important;

}

.mt-5-newcardtwo
{
  margin-top: 60px !important;
}

#tokenBalance .card_cutout
{
  margin-top: 20px !important;
}

.note_sec_p_new
{
  color:#A7A4A4 !important;
  font-size: 14px !important;
}

.theme_scrol_mr.themescroll
{
  max-height: 400px !important;
}

.headfont_po
{
  font-size: 16px !important;
}
.margi_righthed_btn
{
  margin-right: 65px;
}
.margi_righthed_btn .form-control
{
  border: 1px solid #8d8d8d !important;
  /* font-size: 14px !important; */
}
.margi_righthed_btn p
{
  color:#FCFCFC;
  font-size: 13px !important;
}
.btn-outline-danger
{
  border-color: #FF0034 !important;
  color: #FF0034 !important;

}
.btn-outline-danger:hover
{
  background-color: #FF0034 !important;

  color:#fff !important;
}

.btn-outline-success
{
  border-color: #65A819 !important;
  color: #65A819 !important;

}
.btn-outline-success:hover
{
  background-color: #65A819 !important;

  color:#fff !important;
}
.waiz_modal_label
{
  color: #e6e6e6 !important;
    font-family: 'Poppins' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}
.prof_name_new
{
  color:#B4B2B2;
  font-size: 16px;
}
.prof_des_new
{
  color:#B4B2B2;
  font-size: 13px !important;
  margin-top: 5px !important;
}

.flex_shoe_cent
{
  display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    height: 100%;
}
.card_who_div_pos .purplecard,.card_who_div_pos .aquacard,
.card_who_div_pos .yellowcard
{
  position: relative;
}
.card_who_div_pos .coinimg
{
border-radius: 50%;
/* position: absolute;
top:10px;
left:10px; */
}
.h5_tex_coin
{
  font-size: 15px !important;
}
.flex_titl_btn
{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.font_new_profbig
{
  font-size: 16px;
}
.font_new_profsmall
{
  color:#939292 !important;
  font-size: 14px !important;
}
.notecont_without_border
{
  color:#6e6d6f !important;
  font-size: 12px !important;
}
.bank_deta_cards p
{
  font-size: 12px !important;
}
.input-group .btn-primary
{
  border-radius: 0px !important;
}
.exbtn img
{
  max-width: 25px;
}

.rdt_Table div
{
  color: #d7d7d7 !important;
  font-size: 13px !important;
  background-color:transparent;
}

.form-switch .form-check-input
{
  background-image: url(../src/Assets/Images/swith_rad_dot.png);
  background-size: 20px 20px;

}

.form-switch .form-check-input:checked {
  background-size: 20px 20px;
  background-image: url(../src/Assets/Images/swith_rad_dot.png);

}




.div_dt_tabl_scroll>div:first-child::-webkit-scrollbar


  {  width: 0.5em;height: 5px;
  background-color:  #a889fd;
}

.div_dt_tabl_scroll>div:first-child::-webkit-scrollbar-thumb {
height: 5px;
  background-color:  #c2d2e4;
}



.div_dt_tabl_scroll>div:first-child
{
  scrollbar-color: #a889fd #c2d2e4;
  scrollbar-width: thin;
  
}

.bank_detal_bg_table
{
  background-color:#1b1b1f !important;
}
.input_search_bg_prof
{
  background-color: transparent !important;
  /* background-image: url(../src/Assets/Images/btn_bg.png); */
  /* background-image: url(../src/Assets/Images/crypto_btn.svg); */
border:1px solid #4a4949 !important;
border-radius: 0px !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #aeacac;
  font-size: 13px;

  /* border:none !important; */
}
.input_search_bg_prof input,.input_search_bg_prof .input-group-text
{
  border:none !important;
  color: #aeacac;
  font-size: 13px;

  /* padding: 0px !important; */

}
.input_search_bg_prof input::placeholder
{
  color: #aeacac;
  font-size: 13px;

}


.btn-theme-bordered
{
  background: linear-gradient(90deg, #14141440 , #0f0f11b5) !important;
  border: 1px solid #484747;
  color: #c2bcbc !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    border-radius: 0px !important;
    padding: 0px !important;
}
.cryptfiatlist button.btn-theme-bordered
{
  border-radius: 0px !important;
  padding: 0px !important;

}
.cryptfiatlist button.btn-theme-bordered:hover
{
  background: linear-gradient(254deg, #316584 0%, #72579D 100%) !important;
}
.cryptfiatlist button.btn-theme-bordered:hover .btn-theme-bordered-span
{
  background: linear-gradient(90deg, #12151c, #12151c);
}
.cryptfiatlist button.btn-theme-bordered .btn-theme-bordered-span
{
  padding: 4px 15px;
    display: block;
    margin: 1px;
    font-size: 13px !important;
}
.sh_span_margi
{
  margin-top: 6px;
}

.flkex_cent_notes
{
  display: flex;
    align-items: center;
    justify-content: center;
}
.mar_top_wirdw
{
  margin-top: 3px;
}
.p-lg-5.pb-3

{
  padding-bottom: 1rem !important;
}
.alingbtn_end_prof
{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.bnt_eidtprof_modal
{
  background-color: #fff !important;
}
.bnt_eidtprof_modal .fa
{
  color:#000 !important;
}
.card_cutout_exch
{
  background-image: url(../src/Assets/Images/swap_bg_cut.png);
}
.border_0_inut_grp
{
  border-radius: 0px !important;
  background: #191919;
    border-color: #191919;
    background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
    border: 1px solid #484747;
}
.border_0_inut_right
{
  border-right: 0px !important;
}

.noify_deoip_align p
{
  word-break: break-word;
  white-space: break-spaces;
  text-transform: initial !important;
font-weight: 400 !important;
font-size: 13px !important;
}

#responsive-navbar-nav .show.dropdown.noify_deoip_align > div
{
min-width:280px !important;
}

.show.dropdown.noify_deoip_align > div a:hover,
.show.dropdown.noify_deoip_align > div a:focus,
.show.dropdown.noify_deoip_align > div a:focus-visible


{
   background-color: transparent !important;
}
.navbar .show.dropdown.noify_deoip_align > div a:hover
{
color:#fff !important;
}

.show.dropdown.noify_deoip_align > div a .d-flex:hover
{
  background-color: #000 !important;

}

.show.dropdown.noify_deoip_align > div a .d-flex
{
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.dropdwo_height_Scrol .dropdown-menu
{
height:100px;
overflow-y: auto;
scrollbar-color: #a889fd #c2d2e4 !important;
scrollbar-width: thin !important;
}
.iconbtn.btn-theme,.iconbtn.btn-theme:hover,.iconbtn.btn-theme:focus
{
  background: none !important;
  min-width: unset !important;
}
.iconbtn.btn-theme:hover,.iconbtn.btn-theme:focus
{
  color:#4A34CF !important;
}


/* css modal */

@media only screen and (min-width:768px)
{
  /* modal_big_hint */

  .modal_big_hint .modal-dialog {
    max-width: 700px !important;
}
}

.headfont_hint
{
  font-size: 16px !important;
}
.desc_hint
{
  color: #A7A4A4;
  font-size: 13px;
  text-align: center;
}
.img_hint
{
  width: unset !important;
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}
.font_12_green
{
  font-size: 12px;
}

.cryptfiatlist img.imng_full_wdh
{
width: 50% !important;
max-width: 50% !important;
}

.header_marque img
{
  max-width: 25px;
  margin-right: 5px;
  margin-left:10px;
}

@media only screen and (min-width:320px)
{
  .heqding_drop_flex
  {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.check_img_resize
{
  max-width: 20px;
  max-height: 20px;
}

.dropdow_open_modal .dropdown-menu
{
height: 78px !important;
}

.exchange_sec_nut .input-group button.btn_max::after
{
content: none !important;

}
.exchange_sec_nut .input-group button.btn_max
{
  border:1px solid #484747;
  color:#6c757d;
  max-height: 31px !important;
}
.wizardinput .input_grp_with_btu .btn_max
{
  background: #686868;
  border:1px solid #686868;
  color:#ccc;
  max-height: 30px !important;
  text-align: center;
  font-size: 11px !important;
  border-radius: 0px !important;


min-width: 100px !important;
}

.wizardinput .input_grp_with_btu .btn_max:hover
{
  background: transparent !important;
  background-color: transparent !important;
}
.exchange_sec_nut .input-group button.btn_max:hover
{
  background-color: #484747;
  color:#fff;
}
.input_grp_with_btu
{
  background: linear-gradient(90deg, #1c1b20 22%, #181b1d 90%, #151515 95%);
  background-color: rgba(0, 0, 0, 0);
border: 1px solid #484747;
border-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
color: #bdbdbd;
font-size: 13px !important;
padding: 5px;
height: 42px;
box-shadow: 0 0 0 0rem rgba(13,110,253,.25) !important;
}
.input_grp_with_btu input
{
  border:none !important;
  max-height: 31px !important;

  background: transparent !important;
}

.accordion_custom_token .accordion-item
{
background-color: transparent !important;
}

.accordion_custom_token .accordion-button
{
  background-color: transparent;
  color:#fff;
  border:none;
}
.accordion_custom_token .accordion-item
{
  border-left: none;
  border-right: none;
  border-top:none;
  border-bottom-color:#484747;
  padding:10px 0px;
}
.accordion_custom_token .accordion-button img
{
  margin-right: 10px;

}
.accordion_custom_token .accordion-button::after
{
  filter: brightness(0) invert(1)
}
.accordion_custom_token .accordion-body
{
  padding: 15px 0px !important;
}
.accordion_custom_token .accordion-button
{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.theme_scrol_mr.themescroll.scroll_data_table_exc
{
max-height: 300px !important;
}
.logo_resize
{
  max-width: 185px;
}
@media only screen and (max-width:575px)
{
  .logo_resize
  {
    max-width: 135px !important;
  }
}

.exchange .input-group div.append_no_tran
{
transform: none !important;
}
.exchange .input-group div.append_no_tran button
{
  background: none !important;
  color:#ccc !important;
  border:1px solid #686868;
}
.exchange .input-group div.append_no_tran button:hover
{
background-color: transparent !important;
background: transparent !important;
}
.exchange .input-group div.append_no_tran
{
border:1px solid #686868;

}
.exchange .input-group div.append_no_tran:hover
{
  background-color: transparent !important;
background: transparent !important;
border:1px solid #686868;

}

.exchange .input-group div.append_no_tran button
{
  margin-left: 10px !important;
  max-height: 30px !important;
  font-size: 11px !important;
}

.btn_flex_depo_inot_mas
{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn_flex_depo_inot_mas .btn_max
{
  background: #686868;
  border:1px solid #686868;
  color:#ccc;
  max-height: 30px !important;
  text-align: center;
  font-size: 11px !important;
  border-radius: 0px !important;


min-width: 100px !important;
  display: inline-flex;
  align-items: center;
  justify-content:center;
}
.btn_flex_depo_inot_mas .btn_max:hover
{
  background-color: transparent !important;
  background: transparent !important;
}

.btn_flex_depo_inot_mas input{
  width: calc(100% - 120px) !important;
}
.carouse_prof_card .aquacard,
.carouse_prof_card .purplecard,
.carouse_prof_card .yellowcard

{
  background-repeat: no-repeat;
height: 100%;
border-radius: 8px;
padding: 15px;
background-position: center;
min-height: 276px;
}

.owl-carousel.carouse_prof_card  .owl-item img
{
width: unset !important;
margin-bottom: 10px;
}

.flex_img_tetx_card_casr
{
  display: flex;
  justify-content: space-between;
}

.colorcards > div.carouse_prof_card 
{
margin-bottom: 0px !important;
padding: 0px !important;
}
.banklist {
  height: 242px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
nav #responsive-navbar-nav {
  min-width: 266px;
}
.marque{
  width: calc(100vw - 500px);
}
.card.card_cutout div[role="table"] {
  background: transparent;
}
#shownotes {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 50px;
  margin: auto;
  max-width: 455px;
  right: 0;
}
.choosenet .dropdown-toggle:hover::before {
  content: "Choose your network";
  position: absolute;
  top: -40px;
  border: 1px solid gray;
  padding: 2px;
  left: 0;
  border-radius: 7px;
  width: 124px;
  white-space: break-spaces;
  line-height: 1.3;
}
.choosenet.choosenet_hover div:hover::before{
  content: "Coming Soon";
  position: absolute;
  top: 3px;
  border: 1px solid gray;
  padding: 2px;
  right: -110px;
  border-radius: 7px;
  width: 100px;
  white-space: break-spaces;
  line-height: 2;
  text-align: center;
}

.copy_btn_theme
{
  padding: 5px 10px;
  background: linear-gradient(90deg, #14141440 , #0f0f11b5) !important;
border: 1px solid #484747;
color: #c2bcbc !important;
font-size: 12px !important;
margin-left: 10px;
}
.copy_btn_theme:hover
{
  background: linear-gradient(254deg, #316584 0%, #72579D 100%) !important;
}
.deposit_topbtns{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.deposit_btns.btn.btn-theme{
  width: 85px;
}
.btn_download
{
  width: 128px !important;
    height: 46px !important;
}
.min_width_bank_column
{
  min-width: 40px;
}
.min_width_accno_column
{
  min-width: 76px;
}
.min_width_accno_value
{
  word-break: break-all;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #a889fd;
  scrollbar-width: thin;
}

::-webkit-scrollbar-thumb {
  background: #a889fd;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px #393812;
  scrollbar-width: 5px;

}

::-webkit-scrollbar-corner {
  background: red;
}

/* 
scrollbar-color: #a889fd #c2d2e4 !important;
scrollbar-width: thin !important; */

.loader_overlay
{
  min-height: calc(100vh + 120px);
background: #2830ab21;
position: relative;
}
.loader_div
{
  position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
z-index: 1;
}
.loader_div img
{
  max-width: 80px;
  animation: bounce 2s ease infinite;
  transition: all 0.5s ease-in-out;

}
.body_over_hidden
{
  overflow-y: hidden;
}


@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }
}
.conts.just_con_remve
{
  justify-content: flex-start !important;
}
.card_ciu_hei
{
  /* min-height: 500px; */
  padding: 45px 20px;
}
.card_ciu_hei .imng_full_wdh
{
max-height: 300px;
}
.span_spacin_btn
{
  padding: 4px 15px;
display: block;
margin: 1px;
font-size: 13px !important;
}
.btrn_hove_gradt:hover
{
  background: linear-gradient(254deg, #316584 0%, #72579D 100%) !important;
}
.btrn_hove_gradt:hover span
{
  background: linear-gradient(90deg, #12151c, #12151c);
}
.word_brak_item_bak .dropdown-item
{
  word-break: break-all;
  white-space: break-spaces;
}
/* .disabled_btn_tab
{
  cursor: no-drop;
  pointer-events: none;
} */
.aquacard_heh
{
  max-height: 276px;
  overflow-y: auto;
  background-size: 100% 100%;
}
.loaderspi,
.loaderspi:before,
.loaderspi:after {
  border-radius: 50%;
}
.loaderspi {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loaderspi:before,
.loaderspi:after {
  position: absolute;
  content: '';
}
.loaderspi:before {
  width: 5.2em;
  height: 10.2em;
  background: #5d63d9;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loaderspi:after {
  width: 5.2em;
  height: 10.2em;
  background: #5d63d9;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



.icon_ig_swao
{
  font-size: 30px;
}
.icon_ig_swao.fa-spinner

{
  color:#5b51c3 !important;
  animation: mymove 2s linear infinite;
  -webkit-animation: mymove 2s linear infinite;
}


@keyframes mymove {
  50% {transform: rotate(180deg);}
}

.swap_cont_icons .fa
{
font-size: 80px;
}

.swap_cont_icons .fa-times-circle-o
{
  color:#ff0034 !important;
  margin-bottom: 20px;
}


.swap_cont_icons .fa-check-circle-o
{
  color:#65a819 !important;
  margin-bottom: 20px;
}

.swp_deta_fort p
{
  font-size: 12px !important;
}

.hridge_sw_bt
{
z-index: 3;
position: relative;
}